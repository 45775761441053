@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Times New Roman', Times, serif;
}

@layer components {
  .card {
    /* Using @apply to implement utilities */
    @apply flex items-center justify-center;
    background-color: theme("colors.twitter-blue");
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    height: 100px;
    transition: transform 0.3s;
  }
}

/* In your index.css file */
.footer {
  @apply bg-gray-900 text-gray-300 py-2 w-full absolute bottom-0 left-0 z-50;
}


/* Prevent horizontal and vertical scrolling */
html, body {
  overflow-x: hidden; /* Disables horizontal scrolling */
  overflow-y: auto;   /* Enables vertical scrolling */
  margin: 0;           /* Removes default body margin */
  padding: 0;          /* Removes default body padding */
  width: 100vw;        /* Ensures the body takes the full width of the viewport */
  height: 100vh;       /* Ensures the body takes the full height of the viewport */
  box-sizing: border-box; /* Makes sure padding and borders are included in the element's size */
}

/* Optional: Make sure other elements also respect this rule */
*,
*::before,
*::after {
  box-sizing: inherit; /* Inherit the box-sizing from html */
  margin: 0; /* Remove any default margin that could cause overflow */
  padding: 0; /* Remove any default padding that could cause overflow */
}

/* Ensure the root container has the correct height */
#root {
  min-height: 100vh;  /* Ensure it fills the viewport height */
  overflow-x: hidden; /* Prevent horizontal scroll */
  overflow-y: auto;   /* Allow vertical scrolling */
}

/* Optional: Fix for fixed backgrounds */
.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure background image covers the entire viewport */
  z-index: -1; /* Keeps background behind content */
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 12px;   /* Set the width of the scrollbar */
  height: 12px;  /* Set the height of the scrollbar (for horizontal scroll) */
}

::-webkit-scrollbar-thumb {
  background-color: #00bcd4;  /* Set the thumb (scroll handle) color */
  border-radius: 10px;        /* Make the thumb rounded */
  border: 3px solid #333;     /* Add a border around the thumb */
}

::-webkit-scrollbar-track {
  background: #1e1e1e;         /* Set the track color */
  border-radius: 10px;         /* Make the track rounded */
}

::-webkit-scrollbar-corner {
  background-color: #1e1e1e;   /* Set the color for the bottom-right corner */
}

/* Optional: Add smooth scrolling */
html {
  scroll-behavior: auto;
}


/* In your index.css file */
.footer {
  background-color: #1a202c; /* Tailwind's gray-900 */
  color: #e2e8f0; /* Tailwind's gray-300 */
  padding: 10px 0;
  width: 100%;
  position: fixed;
  z-index: 10;
}


.footer a {
  color: #00bcd4; /* Link color */
  text-decoration: none; /* Remove underline */
}

.footer a:hover {
  text-decoration: underline; /* Underline on hover */
}